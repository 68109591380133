import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/about/hero"
import Intro from "../components/about/intro"
import Sponsors from "../components/about/sponsors"


const About = () => (
  <Layout>
    <SEO title="About" />
    <Hero />
    <Intro />
    <Sponsors />
  </Layout>
)

export default About
