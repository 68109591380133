import React from "react"
import styled from '@emotion/styled'

const HeroImage = () => {

  const Wrapper = styled.div`
    width: 100%;
    border-top: 4px solid #540B0E;
    background: #f9f9f9;
  `

  const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 4em 1.5em;
    color: #545454;
    height: 100%;

    @media (min-width: 1024px) {
      flex-direction: row;
      align-items: flex-start;
    }
  `

  const Title = styled.h3`
    font-size: 2em;
    color: #F32B38;
    font-family: "Roboto Slab";
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    line-height: 115%;
    margin-bottom: .35em;
    text-align: center;

    @media (min-width: 595px) {
      /* text-align: left; */
    }

    @media (min-width: 1024px) {
      font-size: 2.5em;
      margin-bottom: .75em;
      text-align: left;
    }
  `

  const Left = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    p {
      font-family: "Roboto Slab";
      font-weight: 400;
      margin-bottom: 1.5em;
      -webkit-font-smoothing: antialiased;
      line-height: 115%;
      font-size: 1.25em;
      text-align: center;

      @media (min-width: 595px) {
      }

      @media (min-width: 1024px) {
        margin-bottom: 0;
        font-weight: 400;
        text-align: left;
      }
    }
  `

  const Right = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    @media (min-width: 1024px) {
      padding-top: 1em;
    }

    p {
      margin-bottom: 1em;
      -webkit-font-smoothing: antialiased;

      &:last-child {
        margin-bottom: 0;
      }
    }
  `

  return (
        <Wrapper>
          <InnerWrapper>
            <Left>
              <Title>Promoting local food is<br/>a Fairview tradition.</Title>
              <p>Tracing our roots back nearly 100 years.</p>
            </Left>
            <Right>
              <p>The Fairview Farmers Market traces its roots back nearly 100 years, beginning at the turn of the last century on the corner of Main and Oak Street. This was the site of Fairview's “City Market,” a very popular farmers market that local residents and grocers relied on for their produce and fresh goods. Until 1968, this was where growers came to sell everything from fruits and vegetables to herbs and honey.</p>
              <p>After a hiatus of 30 years, the Fairview Farmers Market was revived in 1994 on the very corner lot where the original market thrived. Fresh, locally grown produce, baked goods and flowers were once again available each Saturday morning from May to mid-October. Today, the Market has since expanded to three locations on seperate days, with approximately 100 vendors each day.</p>
              <p>In 2008, Holiday Market began as a partnership with the Holiday Lights Festival in the Old Market. In 2010, the Holiday Market moved to Memorial Park during the first weekend of December in partnership with STAR Ohio and CDI Corp.</p>
            </Right>
          </InnerWrapper>
        </Wrapper>
  )
}

export default HeroImage
