// import { Link } from "gatsby"
import React from "react"
import styled from '@emotion/styled'

import So from '../../images/so.inline.svg'
import Wf from '../../images/wf.inline.svg'
import Cdi from '../../images/cdi.inline.svg'
import Lh from '../../images/lh.inline.svg'
import Cw from '../../images/cw.inline.svg'
import Tlf from '../../images/tlf.inline.svg'


const Wrapper = styled.div`
  width: 100%;
  background: #f9f9f9;
  padding-bottom: 4em;
`

const SponsorsMenu = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
`

const SponsorsLogos = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: 1200px) {
    width: 90%;
  }

  @media (min-width: 1400px) {
    width: 80%;
  }

  > div {
    width: 50%;
    height: auto;
    padding: 2em;

    @media (min-width: 620px) {
      width: 33.333%;
    }

    @media (min-width: 1024px) {
      width: 16.666%;
    }

    svg {
      max-width: 100%;
    }
  }
}
`

const Title = styled.h3`
  font-size: 2em;
  color: #F32B38;
  font-family: "Roboto Slab";
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  line-height: 115%;
  margin-bottom: .75em;
`

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 3em 1.5em 0;
  color: #545454;
  height: 100%;
  text-align: center;

  p {
    max-width: 70%;
  }
`


const SponsorsWrapper = () => {


  return (
    <Wrapper>
        <SponsorsMenu>
          <BottomWrapper>
            <Title>Our Sponsors & Market Funding</Title>
            <p>Fairview Farmers Market gives great appreciation to all of our sponsors who assist in making each market a success. For more information on becoming a sponsor, please contact <a href="mailto:jhigginbottom@fairviewfm.org">John Higginbottom</a>.</p>
          </BottomWrapper>
          <SponsorsLogos>
            <div><Wf /></div>
            <div><So /></div>
            <div><Cdi /></div>
            <div><Lh /></div>
            <div><Cw /></div>
            <div><Tlf /></div>
          </SponsorsLogos>
        </SponsorsMenu>
    </Wrapper>
  )
}

export default SponsorsWrapper
